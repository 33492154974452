<script setup>
const {filter, filtersStore} = defineProps(["filter", "filtersStore"])

const value = ref(filtersStore.selected_filters[filter.slug]?.value || []);

function setValue(id) {
  filtersStore.updateValue(filter, value.value);
}
 

</script>

<template>
  <w-drop-down-with-click :marginTop="8">
    <template #default>
      <FilterButton :filter="filter" :filtersStore="filtersStore" />
    </template>

    <template #item>
      <div class="flex flex-wrap gap-1">
        <template v-for="(item, idx) in filter.data_source" :key="idx">
          <template v-if="(filter.filter_value_more > 0 && filter.filter_value_more >= idx)">
            <w-checkbox :value="item" @change="setValue()" v-model="value">
              <template #label>
                  {{ item.label_value || item.value }}
                  {{ filter.filter_value_more == idx ? '+' : '' }}
              </template>
            </w-checkbox>
          </template>
        </template>
      </div>
    </template>
  </w-drop-down-with-click>
</template>
