<script setup>
  const props=defineProps(["filter", "filtersStore"])
  const removeDots = (value) => {
    if (!value) return 0;
    if (value == "") return 0;
    const valueStr = String(value);
    return valueStr.replace(/\./g, "");
  };

  const customValue = computed(() => {
    return {
      min: removeDots(
        props.filtersStore.selected_filters[props.filter.slug]?.value?.min
      ),
      max: removeDots(
        props.filtersStore.selected_filters[props.filter.slug]?.value?.max
      ),
    };
  });

  const setValue = (e, field) => {
    let value =parseInt(e)
    
    if (customValue.value[field] == value) {
      return;
    }
    if (typeof value !== "number") {
      return;
    }
    if (value == 0) {
      value = "";
    }

    let newValue = JSON.parse(JSON.stringify(customValue.value));
    newValue[field] = value; 
    props.filtersStore.updateValue(
      props.filter,
      JSON.parse(JSON.stringify(newValue))
    );
  };
</script>

<template>
  <w-drop-down-with-click :marginTop="8">
    <FilterButton :filter="filter" :filtersStore="filtersStore" />

    <template #item>
      <div class="flex item-center gap-2">
        <div class="flex flex-col items-start">
          <small class="">{{ $t("client.minimum") }}</small>
          <WInput type="number"  :name="`min-price`" :isPrice="true" :value="customValue.min"   @input="(newVal) => setValue(newVal, 'min')"     />
        </div>
        <div class="flex flex-col items-start">
          <small class="">{{ $t("client.maximum") }}</small>
          <WInput type="number" :name="`max-price`" :isPrice="true" :value="customValue.max"   @input="(newVal) => setValue(newVal, 'max')"  />
        </div>
      </div>
    </template>
  </w-drop-down-with-click>
</template>
