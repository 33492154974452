<script setup>
import { useFiltersStore } from '~/stores/filters.store';

const categories=ref([])
const filtersStore = useFiltersStore();
categories.value = await filtersStore.fetchCategories();
const selectedCategory=ref(categories.value[0])
await filtersStore.fetchFilters(selectedCategory.value?._id);
await filtersStore.fetchCount();
const listingCount = computed(() => filtersStore.listings_count);
const sortedFilters = computed(() => {
	return filtersStore.filter.features.sort(
        (a, b) => (b.input_type === "remote" ? 1 : 0) - (a.input_type === "remote" ? 1 : 0)
      )
});
async function handleFilterChange(option) {
	selectedCategory.value=option;
	await filtersStore.fetchFilters(selectedCategory.value?._id);
}
</script>
<template>
	<div class="flex flex-col items-center justify-center">
			<filter-multi-taps v-if="categories.length && selectedCategory" :value="selectedCategory" :options="categories" @update="handleFilterChange" :filter="filter" :filtersStore="filtersStore"  />
			<div v-if="filtersStore?.filter" class="w-4/5 h-auto px-5 py-6 bg-black rounded-lg justify-center items-center gap-2.5 flex" style="background-color: rgba(0,0,0, 0.3 );">
					<div :class="`grid grid-cols-${sortedFilters.length>3?3:sortedFilters.length} gap-3 w-full`">
						<template v-for="feature in sortedFilters">
									<FilterElement class="" :filter="feature" :filtersStore="filtersStore" />
						</template>
						<div class="flex  h-full">
							<NuxtLink :to="localePath(`/${filtersStore.filter_url}`)" class="px-10 py-2 h-full bg-indigo-900 rounded justify-center items-center gap-2.5 inline-flex  text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">{{ $t('client.show_listings', { count: listingCount || 0 }) }}</NuxtLink>
						</div>
					</div>
			</div>
    </div>
</template>
