<script>
  export default {
    props: ["filter", "filtersStore"],

    async setup() { }
  }
</script>

<template>
    <div class="flex flex-col w-full">
      <label v-if="filtersStore?.showInputLabel" class="text-gray-700 text-xs font-normal mb-2 ms-1">{{ filter.name }}</label>
      <button id="menu-button"
        class="relative inline-flex w-auto text-left justify-between me-2 rounded-md px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 truncate"
        :class="filtersStore.style?.buttonBackground || 'bg-white'"
        >
          {{ filtersStore.selected_filters[filter.slug]?.label || filter.name }}
          <div class="absolute right-2">
            <i class="fa-solid fa-chevron-down mt-1 ms-1"></i>
          </div>
      </button>
    </div>
</template>