<script setup>

const props=defineProps({
    filter: {
      type: Object,
      required: true
    },
    filtersStore: {
      type: Object,
      required: true
    },
    useSlug: {
      type: Boolean,
      default: false
    }
  })

const value = ref('turkey')
const value_string = ref('Turkey')
const selected_option = ref({})
const  currentLocation=props.filtersStore.currentLocation;
const cities=ref([])
const search=async (q='') => {
      let url = `/api/data/locations?term=${q}`;
      if (props.filtersStore?.customQuery?.location) {
        const queryString = Object.entries(props.filtersStore.customQuery.location)
          .map(([key, value]) => `${key}=${value}`).join('&');
        url += `&${queryString}`;
      }
      cities.value= await $fetch(url);
      return cities.value;
}

search("")


function onSelect(val) {  
			value.value = props.useSlug ? val.slug : (val.url || 'turkey');
			value_string.value = val.name || 'Turkey';   
		  props.filtersStore.updateValue(props.filter, {
				label_value: value_string.value,
				slug: value.value,
			});
}
</script>

<template>
  <div class="relative inline-flex me-2">
    <span v-if="filtersStore?.showInputLabel" class="text-neutral-400 text-xs font-normal mb-2 ms-1">
      {{ filter.name }}
    </span>
    <w-input 
      :select="true"
      :options="cities"
      v-model:value="selected_option"
      class="bg-white rounded-md ring-1 ring-gray-200"
      type="select" 
      name="city"
      :placeholder="currentLocation || selected_option.name || 'Enter Location'"
      trackBy="_id"
      label="name"
      @update:item="onSelect"
    />
  </div>
</template>