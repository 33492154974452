<script>
  export default {
    props: {
      filter: {
        type: Object,
        required: true
      },
      filtersStore: {
        type: Object,
        required: true
      },
      useSlug: {
        type: Boolean,
        default: false
      }
    },

    async setup(props) { }
  }
</script>

<template>
  <template v-if="filter.input_type !== 'remote'">
    <template v-if="filter.input_type === 'radio'">
          <FilterRadio :filter="filter" :filtersStore="filtersStore" />
        </template>
        <template v-if="filter.input_type === 'select'">
          <FilterCheckbox :filter="filter" :filtersStore="filtersStore" />
        </template>
        <template v-if="filter.input_type === 'number' && filter.is_range == true">
          <FilterRange :filter="filter" :filtersStore="filtersStore" />
        </template>
  </template>
	<template v-else>
      <FilterLocation :filter="filter" :filtersStore="filtersStore" :useSlug="useSlug" />
	</template>
</template>