<script setup>

  const props=defineProps(["filter", "filtersStore"])

  const selectedSlug = ref('');
    
  const updateValue = (filter, row) => {
    props.filtersStore.updateValue(filter, row);
    
    if (props.filtersStore.selected_filters[props.filter.slug].value) {
      selectedSlug.value = props.filtersStore.selected_filters[props.filter.slug].value.slug
    }
    // props.filtersStore.fetchCount();
  };
</script>

<template>
  <w-drop-down-with-click :marginTop="8">
    <template #default>
      <FilterButton :filter="filter" :filtersStore="filtersStore" />
    </template>

    <template #item>
      <div class="flex flex-wrap">
        <div v-for="item in filter.data_source" class="m-1">
          <w-button
              @click="updateValue(filter, item)"
              :class="['p-1.5 px-2 text-[16px] rounded-lg',item.slug == selectedSlug ? 'bg-indigo-800 text-white' : 'ring-1 ring-inset ring-current text-indigo-800 ']"
             
            >
            {{ item.label_value || item.value }}
          </w-button>
        </div>
      </div>
    </template>
  </w-drop-down-with-click>
</template>