<template>
    <div class="flex items-center space-x-2">
      <button
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
        :class="[
          'px-4 py-2 rounded-t-lg transition-all duration-300 text-white cursor-pointer min-w-32',
          selected._id == option._id
            ? 'bg-black/30 '
            : 'bg-gray-500/50'
        ]"
      >
        {{ option.name }}
      </button>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  // Props
  const props=defineProps({
    value: {
      type: [String, Number,Object], // Can handle string or number values
      required: true,
    },
    options: {
      type: Array,
      required: true,
      default: () => [], // Array of { label: 'Option Name', value: 'optionValue' }
    },
    filter:{
      type:Object,
      required:true,
    }, 
    filtersStore:{
      type:Object,
      required:true,
    }
  });
  
  // Emits
  const emit = defineEmits(['update']);
  
  // Current selected value
  const selected = ref(props.value);
  
  // Select an option
  const selectOption = (option) => {
    selected.value = option; 
    emit('update', selected.value);

    
    props.filtersStore.updateCategory(option);
  
  };
  selectOption(selected.value)
  </script>
  
  
  